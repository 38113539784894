/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Account {
  display: flex;
  justify-content: space-between;
  margin: 13px 0 0;

  @media screen and ( $tablet-media ) {
    flex-flow: column;
  }
}

.AccountLogin {
  flex-basis: 431px;

  @media screen and ( $tablet-media ) {
    margin: 0 0 48px;
  }
}

.AccountFrame {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  padding: 29px 26px;
}

.AccountLoginHeader {
  padding: 0 0 15px;
}

.AccountRegister {
  flex-basis: 512px;
}

.NewCustomerHeadline {
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  line-height: 20px;
}
