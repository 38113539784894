/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.LogoutContainer {
  display: flex;
  margin: 12px 0 28px;

  @media screen and ( $tablet-media ) {
    flex-flow: column;
  }
}

.LogoutTitle {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
  padding: 0 54px 0 0;

  @media screen and ( $tablet-media ) {
    max-width: 304px;
    padding: 25px 0 32px;
  }

  h2 {
    font-size: $fontSize-xl;
    font-weight: $font-weight-l;
    line-height: 26px;
    padding: 0 0 16px;
  }

  p {
    font-size: $fontSize-sm;
    line-height: 22px;
  }
}

.LogoutImageBox {
  color: $greyDarkest;
  font-family: $fontFamily;
  font-size: $fontSize-xxl;
  left: 9%;
  line-height: 36px;
  position: absolute;
  top: 30%;

  @media screen and ( $custom-620-media ) {
    left: 3%;
    top: 25%;
  }
}

.LogoutImage {
  background: url('../../../../../../assets/images/account/logout/freundewerbenfreunde.jpg') no-repeat;
  background-size: 100% 200px;
  height: 200px;
  position: relative;
  width: 100%;

  @media screen and ( $custom-620-media ) {
    background-position: 50% 0;
    background-size: cover;
  }

  a {
    bottom: 0;
    margin: 0 16px 20px 0;
    position: absolute;
    right: 0;
    width: 44%;

    @media screen and ( $custom-620-media ) {
      margin: 0 10px 20px;
      width: 95%;
    }
  }
}
