/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ForgotPassword {
  display: flex;
  justify-content: space-between;
  margin: 13px 0 0;

  @media screen and ( $tablet-media ) {
    flex-flow: column;
  }

  :global {

    .themed-form-field-error {

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.ForgotPasswordWrapper {
  flex-basis: 431px;
}

.ForgotPasswordFrame {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  padding: 29px 26px;
}

.ForgotPasswordHeader {
  color: $greyDarkest;
  padding: 0 0 15px;
}

.ForgotPasswordText {
  color: $greyDarkest;
  font-family: $fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-weight-m;
  line-height: 22px;
  padding: 0 0 18px;
}

.ForgotPasswordButtonWrapper {
  align-items: center;
  display: flex;
  flex-flow: column;

  button {
    margin: 0 0 30px;
    max-width: 250px;
    width: 100%;
  }
}

.ForgotPasswordInput {
  margin: 0 0 25px;
  position: relative;
}

.AccountRegister {
  flex-basis: 512px;
}

.popup {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}

.popupInner {
  background-color: $asamGreenDarkest;
  bottom: 25%;
  left: 25%;
  margin: 0 auto;
  position: absolute;
  right: 25%;
  top: 25%;
  z-index: 1;
}
