/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Benefits {
  margin: 50px 0 0;
  text-align: center;

  @media screen and ( $tablet-media ) {
    display: none;
  }
}

.BenefitsHeader {
  font-family: $fontFamily;
  font-size: 28px;
  font-weight: $font-weight-m;
}

.BenefitsBar {

  .BenefitsWrapper {
    display: grid;
    grid-column-gap: 5px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .BenefitsIcon {
    align-items: center;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 50px auto;
  }

  .BenefitsIconText {
    font-size: $fontSize-s;
  }
}

.BenefitsWrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding: 16px 0;
}

.BenefitsIconText {
  color: $grey;
  font-size: $fontSize-sm;
  font-weight: $font-weight-m;
  line-height: 20px;
  max-width: 100px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .PreFooter {
    padding: 4px 0;
  }

  .PreFooterIcon {
    display: block;
    padding-left: 0;
    text-align: center;
  }

  .PreFooterIconText {
    margin-left: auto;
    margin-right: auto;
    max-width: 108px;
  }
}

@media screen and ( $mobile-media ) {

  .BenefitsBar {

    .BenefitsWrapper {
      justify-items: center;
    }

    .BenefitsIcon {
      display: block;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .BenefitsIconText {
      text-align: center;
    }
  }

  .PreFooterIcon {
    width: 33%;

    &:last-child {
      display: none;
    }
  }
}

@media screen and ( $custom-370-media ) {

  .PreFooter {
    display: block;
  }

  .PreFooterIcon {
    margin: 10px 0;
    width: 100%;
  }
}
