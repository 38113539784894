/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.RegisterFormTerms {
  position: relative;

  :global {

    .normal {

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.RegisterFormRequired {
  bottom: 0;
  color: $redError;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 0 0 0 35px;
  position: absolute;

  &:not(:empty) {

    + div span {
      color: $redError;
    }
  }
}

.RegisterForm {
  margin-bottom: 51px;

  :global {

    [ class *= 'FormFieldWarning' ],
    .themed-form-field-error {
      bottom: -36px;
      position: absolute;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.RegisterFormInput {
  margin: 0 0 24px;
  position: relative;
}

.RegisterFormInputWrapper,
.RegisterFormRadioWrapper {
  display: flex;
}

.NewCustomerHeadline {
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  line-height: 20px;
}

.RegisterFormRadioWrapper {
  margin: 32px 0 20px;

  > * {
    margin: 0 38px 0 0;
  }
}

.RegisterFormInputPostcode {
  flex-basis: 160px;
}

.RegisterFormInputCity {
  flex-grow: 1;
  margin: 0 0 0 20px;
}

.RegisterFormInputCity, .RegisterFormInputPostcode, .RegisterFormInputPassword, .RegisterFormInputConfirmPassword {

  :global {

    .themed-form-field-error {
      bottom: 0;
      position: relative;
    }
  }
}

.RegisterFormAgreementsWrapper {
  margin-bottom: 41px;

  label {
    color: $greyDarkest;
    line-height: 20px;
    margin: 0 0 19px;

    a {
      text-decoration: underline;
    }
  }
}

.RegisterFormButtonsWrapper {
  margin-top: 10px;
  text-align: center;

  :global {

    .LabelWithDashesText {
      display: block;
      font-size: 12px;
      font-weight: $font-weight-m;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  a,
  button {
    margin: 15px auto 0;
    min-width: 240px;
    width: auto;
  }
}

.RadioWrapper {
  display: flex;
  margin: 32px 0 20px;
}

.RadioInputMargin {
  margin: 0 38px 0 0;
}

.RegisterError {
  -webkit-appearance: none;
  appearance: none;
  background: rgba(226, 0, 0, .1);
  border: 1px solid $redError;
  border-radius: 8px;
  color: $greyDarkest;
  display: block;
  font-family: Open Sans, system-ui, sans-serif;
  font-size: 16px;
  height: 40px;
  line-height: 22px;
  margin-bottom: 24px;
  outline: none;
  padding: 9px 14px;
  transition: all .4s cubic-bezier(.215, .61, .355, 1);
  width: 100%;
}

.RegisterFormOther {
  margin-top: 22px;

  button {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.is-invalid {
  border: 1px solid red;
}

.RegisterInput {
  -webkit-appearance: none;
  appearance: none;
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  color: $greyDarkest;
  display: block;
  font-family: Open Sans, system-ui, sans-serif;
  font-size: 16px;
  height: 40px;
  line-height: 22px;
  margin-bottom: 24px;
  outline: none;
  padding: 9px 14px;
  transition: all .4s cubic-bezier(.215, .61, .355, 1);
  width: 100%;
}

.RegisterInputError {
  color: $redError;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.InputWrapper {
  display: flex;
}

.ErrorIcon {
  line-height: 1;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    display: block;
    height: 16px;
    width: 16px;
  }
}

.error {
  background: rgba(226, 0, 0, .1);
  border: 1px solid #e20000;
}
