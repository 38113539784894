/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.AddressSuggestions {
  background: $orangeInfoAlpha;
  border: 1px solid $orangeInfo;
  border-radius: 8px;
  margin: 0 0 20px;
  padding: 24px 48px;
  position: relative;
}

.AddressSuggestionsTitle {
  color: $greyDarkest;
  font-size: $fontSize-l;
  line-height: 20px;
  margin-bottom: 24px;
}

.AddressSuggestionsRadio {
  margin-bottom: 16px;
}

.AddressSuggestionsIcon {
  position: absolute;
  right: 14px;
  top: 16px;

  &:before {
    color: $orangeInfo;
    font-size: $fontSize-Icon24;
  }
}

/* ------------------------------------*\
  #RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .AddressSuggestions {
    padding: 24px 16px;
  }
}
