/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.LoginStep {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 32px 0;
}

.LoginStepLoginButton {
  width: 320px;
}

.LoginStepButton {
  font-size: 0;
  max-width: 250px;
  text-align: center;

  button, a {
    margin-bottom: 0;
    margin-top: 0;

    & ~ button,
    & ~ a {
      margin-top: 15px;
    }
  }

  @media screen and ( $mobile-media ) {
    margin: 15px auto;
  }

  & + .LoginStepButton {
    @media screen and ( $desktop-only-media ) {
      margin-left: 20px;
    }
  }
}

.LoginStepOr {
  color: $grey;
  font-size: 10px;
  font-weight: $font-weight-m;
  line-height: 12px;
  padding: 0 16px;
  text-transform: uppercase;
  white-space: nowrap;
}

.LoginStepSocialLogin {
  align-items: center;
  display: inline-flex;
  justify-content: center;

  button {
    margin-left: 0;
    margin-right: initial;
    white-space: nowrap;
    width: 240px;
  }
}

.LoginPopupHeader {
  font-size: 18px;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-bottom: 16px;
}

.LoginPopupText {
  color: $greyDarkest;
  font-family: $fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-weight-m;
  line-height: 22px;
  padding: 0 0 18px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .LoginStepSocialLogin {
    display: block;

    .LoginStepButton {

      &:last-child {
        margin-top: 15px;
      }
    }
  }
}

@media screen and ( $mobile-media ) {

  .LoginStep {
    display: block;
    text-align: center;
  }

  .LoginStepLoginButton {
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
  }

  .LoginStepSocialLogin {
    margin-top: 12px;

    button {

      &:first-child, &:last-child {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media screen and ( $custom-370-media ) {

  .LoginStepLoginButton {
    width: 290px;
  }
}
